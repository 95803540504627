<router>
{
    name: 'Task Queue',
}
</router>

<template lang="pug">
.analytics__queue
    v-container
        v-row
            v-col
                Queue

</template>

<script lang="js">
import Queue from '@/components/analytics/queue.vue';

export default {
    meta: {
        role: 'analytics-queue'
    },
    components: {
        Queue
    },
    data () {
        return {};
    },
    created () {
        this.$store.dispatch('connectSocket');
    },
};
</script>
