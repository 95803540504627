
<template lang="pug">
.analytics__queue
    v-container(fluid)
        v-card(light)
            v-card-title Analytics Task Queue
            v-card-text
                v-row(align="center")
                    v-col(cols="auto")
                        v-text-field(v-model="search" label="Search" clearable color='brand')
                    v-col(cols="auto")
                        v-btn(@click="fetchQueue" color="brand" small class="white--text") Refresh
                    v-col(cols="auto")
                        v-tooltip(right)
                            template(v-slot:activator="{ on, attrs }")
                                v-btn(
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="isAutoRefresh ? 'red' : 'green'"
                                    small
                                    class="white--text"
                                    @click="toggleAutoRefresh"
                                )
                                    | {{ isAutoRefresh ? 'Stop Auto-Refresh' : 'Start Auto-Refresh' }}
                            span The refresh rate is 5 seconds
                v-data-table(
                    :headers="headers"
                    :items="tasks"
                    :search="search"
                    :items-per-page="5"
                    item-key="id"
                )
                    template(v-slot:item.id="{ item }")
                        span {{ item.id }}
                            v-icon(small @click="showHistory(item)") history

                    template(v-slot:item.status="{ item }")
                        v-chip(:color="getStatusColor(item.status)" dark) {{ item.status }}
                    template(v-slot:item.createdAt="{ item }")
                        div
                            | {{ formatDate(item.createdAt, 'date') }}
                            br
                            | {{ formatDate(item.createdAt, 'time') }}
                    template(#item.updatedAt="{ item }")
                        div
                            | {{ formatDate(item.updatedAt, 'date') }}
                            br
                            | {{ formatDate(item.updatedAt, 'time') }}
                    template(v-slot:item.message="{ item }")
                        span(style="font-size: 0.8em;") {{ item.message }}
</template>

<script>

import moment from 'moment';
export default {
    meta: {
        role: 'analytics-queue'
    },
    emits: [],
    data () {
        return {
            search: '',
            headers: [
                { text: 'Q ID', value: 'id' },
                { text: 'Task ID', value: 'task_id' },
                { text: 'System ID', value: 'system_id' },
                { text: 'Name', value: 'taskInfo.task_name' },
                { text: 'Status', value: 'status' },
                { text: 'Attempts', value: 'attempts_count' },
                { text: 'Created At', value: 'createdAt' },
                { text: 'Updated At', value: 'updatedAt' },
                { text: 'Message', value: 'message' }
            ],
            tasks: [],
            error: '',
            isAutoRefresh: false, // Flag to track auto-refresh state
            autoRefreshInterval: null // Interval ID for auto-refresh
        };
    },
    methods: {
        async fetchQueue () {
            console.log('fetchQueue');
            this.socketEmit('analyticsTaskQueue', null, ({queue, error}) => {
                if(error) {
                    this.$set(this, 'error', error);
                    console.error('Error fetching tasks:', error);
                } else {
                    this.$set(this, 'tasks', queue);
                }
            });
        },
        toggleAutoRefresh () {
            if (this.isAutoRefresh) {
                // Stop auto-refresh
                clearInterval(this.autoRefreshInterval);
                this.autoRefreshInterval = null;
                this.isAutoRefresh = false;
            } else {
                // Start auto-refresh
                this.autoRefreshInterval = setInterval(this.fetchQueue, 5000); // Refresh every 5 seconds
                this.isAutoRefresh = true;
            }
        },
        getStatusColor (status) {
            const colors = {
                PENDING: 'grey',
                IN_PROGRESS: 'blue',
                COMPLETED: 'green',
                FAILED: 'red',
                EXPIRED: 'orange',
                WAITING: 'teal'
            };
            return colors[status] || 'grey';
        },
        formatDate (dateString, type) {
            if (!dateString) return '';
            if (type === 'date') {
                return moment(dateString).format('MM/DD');
            } else if (type === 'time') {
                return moment(dateString).format('h:mm:ss a');
            }
            return moment(dateString).format('MMM DD, h:mm:ss a');
        },

        showHistory (item){
            console.log('showHistory', item);
        }
    },
    mounted () {
        this.fetchQueue();
    }
};
</script>

<style scoped>
.v-container {
  max-width: 900px;
}
</style>
